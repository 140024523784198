import { Component, type OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { TableComponent } from '@core/components/table/table.component';
import { type TableConfig, type TableFilter } from '@core/models/table';
import { type CompayAccessRequest, RequestStatus } from '../models/company-access-requests';
import { ModalService } from '@core/services/modal.service';
import { ConfirmApproveModalComponent } from '../modals/confirm-approve-modal/confirm-approve-modal.component';
import { ConfirmDenyModalComponent } from '../modals/confirm-deny-modal/confirm-deny-modal.component';
import { ToastrService } from '@core/services/toastr.service';
import { DateFormatDistancePipe } from '../../core/pipes/date-format.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { CompanyLogoComponent } from '../../core/components/company-logo/company-logo.component';
import { UserLogoComponent } from '../../core/components/user-logo/user-logo.component';
import { TableComponent as TableComponent_1 } from '../../core/components/table/table.component';

@Component({
  selector: 'app-company-access-requests-page',
  templateUrl: './company-access-requests-page.component.html',
  styleUrls: ['./company-access-requests-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableComponent_1,
    UserLogoComponent,
    CompanyLogoComponent,
    ButtonLoadingDirective,
    TranslateModule,
    DateFormatDistancePipe,
  ],
})
export class CompanyAccessRequestsPageComponent implements OnInit {
  private readonly modalService = inject(ModalService);
  private readonly toastr = inject(ToastrService);
  @ViewChild('table') table: TableComponent;
  public data: CompayAccessRequest[] = [];
  public tableConfig: TableConfig;

  public isLoading = false;

  public ngOnInit(): void {
    this.generateConfig();
  }

  public requestDenied(accessRequest: CompayAccessRequest): boolean {
    return accessRequest.status === RequestStatus.Declined;
  }

  public requestApproved(accessRequest: CompayAccessRequest): boolean {
    return accessRequest.status === RequestStatus.Approved;
  }

  public deny(id: string): void {
    this.modalService
      .open({
        content: ConfirmDenyModalComponent,
        inputs: {
          id,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.table.loadData(true);
      })
      .catch(() => {});
  }

  public approve(accessRequest: CompayAccessRequest): void {
    if (accessRequest.status === RequestStatus.WaitingEmailConfirmation) {
      this.toastr.error('companyAccessRequests.awaitingEmailApproveError');
      return;
    }

    this.modalService
      .open({
        content: ConfirmApproveModalComponent,
        inputs: {
          id: accessRequest.id,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.table.loadData(true);
      })
      .catch(() => {});
  }

  private generateConfig(): void {
    this.tableConfig = {
      loadUrl: '/api/companyAccessRequests/search',
      loadLimit: 25,
      defaultSort: 'requestedAt',
      defaultSortDirection: 'desc',
      filters: this.generateFilters(),
      columns: [
        {
          name: 'requestedBy',
          label: 'companyAccessRequests.columns.requestedBy',
          sortable: true,
          maxWidth: '1fr',
        },
        {
          name: 'companyAccessRequested',
          label: 'companyAccessRequests.columns.companyAccessRequested',
          sortable: true,
        },
        {
          name: 'requested',
          label: 'companyAccessRequests.columns.requested',
          sortable: true,
        },
        {
          name: 'actstatusions',
          label: 'common.labels.status',
          sortable: true,
        },
        {
          name: 'actions',
          label: 'common.labels.action',
        },
      ],
    };
  }

  private generateFilters(): TableFilter[] {
    return [
      {
        name: 'status',
        queryParam: 'status',
        defaultValue: '',
        options: [
          {
            label: 'companyAccessRequests.requestStatus.all',
            value: '',
          },
          {
            label: 'companyAccessRequests.requestStatus.requested',
            value: RequestStatus.Pending,
          },
          {
            label: 'companyAccessRequests.requestStatus.approved',
            value: RequestStatus.Approved,
          },
          {
            label: 'companyAccessRequests.requestStatus.denied',
            value: RequestStatus.Declined,
          },
          {
            label: 'companyAccessRequests.requestStatus.pendingEmailConfirmation',
            value: RequestStatus.WaitingEmailConfirmation,
          },
        ],
      },
    ];
  }
}
