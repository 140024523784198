import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyAccessRequestsPageComponent } from './company-access-requests-page/company-access-requests-page.component';
import { CompanyAccessRequestsRoutingModule } from './company-access-requests-routing.module';
import { CompanyAccessRequestsService } from './company-access-requests.service';
import { ConfirmApproveModalComponent } from './modals/confirm-approve-modal/confirm-approve-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDenyModalComponent } from './modals/confirm-deny-modal/confirm-deny-modal.component';
import { TableComponent } from '@core/components/table/table.component';
import { UserLogoComponent } from '@core/components/user-logo/user-logo.component';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { DateFormatDistancePipe } from '@core/pipes/date-format.pipe';

@NgModule({
  imports: [
    CommonModule,
    CompanyAccessRequestsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    TableComponent,
    UserLogoComponent,
    CompanyLogoComponent,
    ButtonLoadingDirective,
    DateFormatDistancePipe,
    CompanyAccessRequestsPageComponent,
    ConfirmApproveModalComponent,
    ConfirmDenyModalComponent,
  ],
  providers: [CompanyAccessRequestsService],
  bootstrap: [],
})
export class CompanyAccessRequestsModule {}
